<template>
  <div class="contents userMgt users">
    <div class="title flexB">
      <h2>회원 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option disabled selected value>선택</option>
          <option value="username">이름</option>
          <option value="mobileNo">전화번호</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>
      <div class="flex">
        <p class="bold">카테고리</p>
        <label>
          <input type="radio" value="" checked v-model="userType" />전체
        </label>
        <label
          ><input type="radio" value="USER" v-model="userType" />농장주
        </label>
        <label
          ><input type="radio" value="EXAMINER" v-model="userType" />심사원
        </label>
      </div>
      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>
    <div class="box one">
      <h2 class="bold">회원 리스트 ({{ total }}명)</h2>
      <table>
        <colgroup>
          <col style="width: 10%" />
          <col style="width: 10%" />
          <col style="width: 10%" />
          <col style="width: 10%" />
          <col style="width: 20%" />
          <col style="width: 20%" />
          <col style="width: 20%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>권한</th>
          <th>구분</th>
          <th>이름</th>
          <th>전화번호</th>
          <th>마케팅수신동의</th>
          <th>가입일</th>
        </tr>
        <tr v-for="(data, i) in userList" :key="i">
          <td>{{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}</td>
          <td>{{ data.isAdmin == true ? "관리자" : "일반" }}</td>
          <td>
            {{
              data.userType == "USER"
                ? "농장주"
                : data.userType == "EXAMINER"
                ? "심사원"
                : ""
            }}
          </td>
          <td>
            <router-link to @click.native="handleDetail(data._id)">{{
              data.withdrawAt ? "탈퇴회원" : data.username
            }}</router-link>
          </td>
          <td>{{ getMask(data.mobileNo) }}</td>
          <td>{{ data.marketingAgreement == true ? "동의" : "미동의" }}</td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
        </tr>
      </table>
      <NoData v-if="isNodata" />
      <div class="pagination" v-if="userList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchUserList } from "@/api/admin";
import { format } from "@/mixins/format";
import NoData from "@/components/contents/Nodata";
export default {
  components: { NoData },

  mixins: [format],
  name: "UserManage",
  data() {
    return {
      moment: moment,
      inputMode: "",
      searchWord: "",
      userList: [],
      userType: "",
      total: 0,
      currentPage: 1,
      isNodata: false,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "1");
  },
  mounted() {
    this.getUserList();
  },
  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    handleDetail(id) {
      this.$router.push({ name: "userDetail", query: { id: id } });
    },
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.userType = "";
      this.userList = [];
      this.total = 0;
      this.currentPage = 1;
      this.getUserList();
    },
    search() {
      if (this.searchWord != "" && this.inputMode == "") {
        this.openAlert("검색 조건을 선택해 주세요.");
        return;
      }
      this.getUserList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserList();
    },
    getUserList() {
      let params = {
        page: this.currentPage,
        inputMode: this.inputMode,
        searchWord: this.searchWord,
        userType: this.userType,
      };
      fetchUserList(params).then((res) => {
        if (res.data.status == 200) {
          this.userList = res.data.users;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
          this.currentPage = res.data.page;
        } else if (res.data.status == 409) {
          this.$router.push("/");
          return this.openAlert(res.data.message);
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
  },
};
</script>
